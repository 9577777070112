<template>
    <div class="api-service">
        <a-row :gutter="2" type="flex" justify="space-between">
            <a-col :span="5">
                 <a-menu
                     :default-selected-keys="['1']"
                     :default-open-keys="['flow']"
                     mode="inline"
                     theme="light"
                 >
                                  <a-sub-menu  v-for="item in menus" :key = "item.key" @click="changeMenu">
                                    <span slot = "title"><span>{{item.name}}</span></span>
                                    <a-menu-item v-for="child in item.children" :key = "child.key">
                                      {{ child.name }}
                                    </a-menu-item>
                                  </a-sub-menu>
                                </a-menu>

            </a-col>
            <a-col :span="18">
                <div class="api-test-content">
                    <a-row style="margin-bottom: 16px;"><a-col>{{currentContent.title}}</a-col></a-row>
                    <a-row style="margin-bottom: 16px;"><a-col><div v-html="currentContent.content"></div></a-col></a-row>

                </div>
            </a-col>
        </a-row>
    </div>
</template>

<script>
import service from "../../../utils/request";

export default {
    name: "api-service",
    data(){
        return {
            menus:[
                {name:'接口使用流程说明', key: 'flow', children:[
                        {name:'接口使用流程',key: '1',},
                        {name:'sign生成规则',key: '2',},
                    ]},
                {name:'统一错误码说明', key: 'error-code', children:[
                        {name:'统一错误码说明',key: '3',},
                    ]},
                {name:'消息枚举值说明', key:'message', children:[
                        {name:'订单类消息类型说明', key:'4'},
                        {name:'商品类消息类型说明', key:'5'},
                    ]},
                {name:'订单售后申请原因说明文档', key:'message', children:[
                        {name:'订单售后申请原因说明文档', key:'6'},
                    ]},
                {name:'物流公司对应编号', key:'message', children:[
                        {name:'物流公司对应编号', key:'7'},
                    ]}
            ],
            currentContent:{},
            content:{
                1: {
                    title: '接口使用流程',
                    desc:'获取分类信息。 1.输入默认值0查询所有一级分类（即入参 "id": 0） 2.输入一级分类id，查询对应二级id',
                    paramsDemo:'{"id":0}',
                },
            },

        }
    },
    created() {
        this.getDocument(1);
    },
    methods:{
        changeMenu(item){
            this.getDocument(item.key)
        },
        getDocument(key){
            service.post(service.uri.document.getDocument, {key: key}).then(res => {
                this.currentContent = res.data
            });
        }
    }
}
</script>

<style lang="less" scoped>
.api-test-content{
    background-color: #fff;
    padding: 27px;
}
.api-title{
    font-size: 18px;
    color: #333;
    padding-bottom: 12px;
}
</style>